import React from 'react'
import logo from '../../Assets/logo.png'
import './Hero.css'
// import Construction_logo from '../../Assets/Construction_logo.png'
// import Construction from '../Const_Svg/Construction.js';

const Hero = () => {
  return (
    <div className='container mx-auto h-screen flex items-center justify-center'>
    <div className=''>
        <div>
            <img className='logo size-64 ' src={logo} alt="" />
        </div>
        <div>
            <h1 className='uppercase font-bold text-xl mt-10'>Under Construction...</h1>
            </div>
            
        {/* <img className='size-24 ' src={Construction_logo} alt="" /> */}
       
        
        {/* <Construction /> */}
    </div>
    </div>
  )
}

export default Hero